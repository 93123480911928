import React from "react";

import { Layout, SEO } from "../../../../components/structure";
import {
  Button,
  Content,
  Hero,
  CallToAction,
  HospitalityGrid,
} from "../../../../components/blocks";
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

const HospitalityIntegrationsPage = props => {
  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "GettyImages-1172128727.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      rightArrowIconWhite: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
      airIcon: file(base: { eq: "AirplaneIcon.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      clickUpgradeIcon: file(base: { eq: "click-upgrade.png" }) {
        childImageSharp {
          fixed(width: 45, height: 80, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      partnerIncrease: file(base: { eq: "partnerIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      revenueIncrease: file(base: { eq: "revenueIncrease.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      cloudUpload: file(base: { eq: "cloudUpload.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      creditCard: file(base: { eq: "creditCard.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lcrProductImage: file(base: { eq: "LCR-Device-SWA.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      loyaltyIcon: file(base: { eq: "handPlus.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      speedPassProduct: file(base: { eq: "Product-Speed-Pass.png" }) {
        childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      offerDistribute: file(base: { eq: "offerDistribute.png" }) {
        childImageSharp {
          fixed(width: 70, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      revPlatformBackground: file(
        base: { eq: "IndustryCruiseRevenuePlatform.jpg" }
      ) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airTestimonials: file(base: { eq: "IndustryAirTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      growthArrow: file(base: { eq: "ArrowClimber.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      allHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileallHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      airCanada: file(base: { eq: "AC_Inline.png" }) {
        ...CruiseLogo
      }
      lingus: file(base: { eq: "AerLingus_Inline.png" }) {
        ...CruiseLogo
      }
      latam: file(base: { eq: "Latam_Inline.png" }) {
        ...CruiseLogo
      }
      va: file(base: { eq: "VA_Inline.png" }) {
        ...CruiseLogo
      }
      lufthansa: file(base: { eq: "Lufthansa_Inline.png" }) {
        ...CruiseLogo
      }
      singapore: file(base: { eq: "Singapore_Inline.png" }) {
        ...CruiseLogo
      }
      etihad: file(base: { eq: "EtihadGold.png" }) {
        ...CruiseLogo
      }
      qantas: file(base: { eq: "Qantas.png" }) {
        ...CruiseLogo
      }
      whiteLabel: file(base: { eq: "whiteLabelIcon.png" }) {
        childImageSharp {
          fixed(width: 110, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      zeroDilution: file(base: { eq: "zeroDilutionIcon.png" }) {
        childImageSharp {
          fixed(width: 140, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      provenGrowth: file(base: { eq: "provenGrowth.png" }) {
        childImageSharp {
          fixed(width: 120, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      merchantRecord: file(base: { eq: "merchantRecordIcon.png" }) {
        childImageSharp {
          fixed(width: 90, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fullInt: file(base: { eq: "fullIntegrationIcon.png" }) {
        childImageSharp {
          fixed(width: 70, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fastestTime: file(base: { eq: "fastestTimeIcon.png" }) {
        childImageSharp {
          fixed(width: 95, height: 110, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      oracleLogo: file(base: { eq: "oracle-hospitality.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      synxisLogo: file(base: { eq: "synxis-sabre-1.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mewsLogo: file(base: { eq: "mews-logo-2020.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sihotLogo: file(base: { eq: "Sihot.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      siteminderLogo: file(base: { eq: "siteminder-logo.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      optimalLogo: file(base: { eq: "OptimaSilverbyte.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      webhotelierLogo: file(base: { eq: "Webhotelier_copy.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      totvsLogo: file(base: { eq: "TOTVS.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      apaleoLogo: file(base: { eq: "apaleo.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hotelRunnerLogo: file(base: { eq: "HotelRunner.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      simpleBookingLogo: file(base: { eq: "simpleBooking.jpeg" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      winHotelLogo: file(base: { eq: "Winhotel.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      roiBackLogo: file(base: { eq: "RoiBack.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      dingusLogo: file(base: { eq: "dingus.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      verticalLogo: file(base: { eq: "Vertical_Booking_Logo.jpg" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fnsLogo: file(base: { eq: "Logo-FNSrooms-2022.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      tcaLogo: file(base: { eq: "TCAInnsist.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      rmsLogo: file(base: { eq: "RMS.jpeg" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      stayntouchLogo: file(base: { eq: "Stayntouch.png" }) {
        childImageSharp {
          fixed(width: 120, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      inforLogo: file(base: { eq: "Infor.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      hostLogo: file(base: { eq: "HostHotelSystems.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      oracleOperaLogo: file(base: { eq: "OracleOpera.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      operaCloudLogo: file(base: { eq: "OperaCloud.png" }) {
        childImageSharp {
          fixed(width: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      simpleUX: file(base: { eq: "simpleUX.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      generateAncillary: file(base: { eq: "generateAncillary.png" }) {
        childImageSharp {
          fixed(width: 58, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      inventoryControl: file(base: { eq: "inventoryControl.png" }) {
        childImageSharp {
          fixed(width: 50, height: 60, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HeroRightArrow: file(base: { eq: "plusgrade-blue-arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: ${brand.colors.pg_primary_light};

    .left-section {
      flex-basis: 50%;
      height: 450px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 22px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const ProductsHeroContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    /*margin: 80px auto;*/
    margin: 80px auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2%;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
    }

    > div {
      flex-basis: 48%;
    }

    .graphic-con {
      width: 100%;
      max-width: 495px;
      height: 470px;

      @media only screen and ${mq.maxMd} {
        align-self: flex-start;
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .title-con {
      h2 {
        white-space: pre-wrap;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 80px;
        color: ${brand.colors.pg_orange};
        padding-top: 135px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          font-size: 60px;
        }
      }
    }
  `;

  const PlatformDetailContainer = styled.div`
    width: 100%;
    background-color: ${brand.colors.pg_blue};

    .inner-icon-container {
      width: 95%;
      max-width: 1020px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      gap: 40px;

      @media only screen and ${mq.maxMd} {
        width: 80%;
      }
    }

    .platform-headline {
      display: inline-block;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-top: 2rem;

      /*
       .headline-con {
         margin-bottom:0;
       }
       */
    }
  `;
  const LogoGrid = styled.div`
  /*width: 100%;*/
  max-width: 1400px;
  /*margin: 80px auto;*/
  margin: 80px auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 80px 0;
  /*display: flex;*/
  
  h2 {
    color: ${brand.colors.pg_primary_dark};
    font-size: 26px;
    width:100%;
    text-align: center;
    // margin-top:2em;
      @media only screen and (max-width: 400px) {
        font-size: 22px;
      }
    }

  .logo-grid-container {

    align-items: center;
    justify-content: space-between;
    gap: 2%;
    grid-row-gap: 20px; 
    display: grid;
    /*grid-gap: 1rem;*/
    grid-template-columns: repeat(6, 1fr);   
    padding: 2em;

    @media only screen and ${mq.maxMd} {
      grid-template-columns: repeat(2, 1fr); 
      grid-auto-rows: 1fr;
      padding: 2em;
    }
 


    .headline-con {
      p {
        color: #080A12 !important;
      }
    }

    div {
      color: black;
    }


    ${props =>
      props.lightGreyBg &&
      css`
        background-color: ${brand.colors.pg_primary_light};
      `}`;

  return (
    <Layout>
      <SEO
        title="Intégrations | Plusgrade"
        description={
          "Plusgrade accelerates your incredible passenger experiences while generating significant ancillary revenue on every flight."
        }
        keywords={"airline, flight, upgrades"}
        lang="fr"
      />
      <Content whiteBG>
        <Hero
          title={"Intégrations"}
          image={pageQuery["hero"]}
          subtitle={
            "Nos offres auxiliaires s’intègrent parfaitement à votre système de gestion immobilière existant et à vos outils préférés."
          }
          fullBG
        />

        <CallToAction
          primaryLightBg
          title={
            "Amplifiez les expériences de vos clients et améliorer la rentabilité"
          }
          subtitle={
            "Notre plateforme s’intègre aux systèmes de gestion immobilière (PMS), aux moteurs de réservation et aux systèmes de gestion des canaux de\ndistribution les plus performants afin d’automatiser vos initiatives de vente incitative et d’améliorer la satisfaction de vos clients."
          }
          style={{ paddingBottom: "0px" }}
        >
          <Button
            destination={"/fr/contact/demandez-une-demonstration/"}
            label={"Pour démarrer"}
            icon={pageQuery["rightArrowIconWhite"]}
            blue
            whiteText
            pill
          />
        </CallToAction>

        <Content secondaryLightBG>
          <LogoGrid lightGreyBg>
            <h2>Systèmes PMS</h2>

            <div className="logo-grid-container">
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["oracleLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["mewsLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["sihotLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["optimalLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["apaleoLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["winHotelLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["tcaLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["rmsLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["stayntouchLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["inforLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["hostLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["operaCloudLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["oracleOperaLogo"]}
              />
            </div>
          </LogoGrid>
        </Content>

        <Content whiteBG>
          <LogoGrid>
            <h2>Gestionnaires de canaux et moteur de réservation</h2>

            <div className="logo-grid-container">
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["synxisLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["webhotelierLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["totvsLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["hotelRunnerLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["apaleoLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["simpleBookingLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["roiBackLogo"]}
              />

              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["verticalLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["fnsLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["rmsLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["dingusLogo"]}
              />
              <HospitalityGrid
                dropShadow
                style={{ background: "#ffffff" }}
                icon={pageQuery["siteminderLogo"]}
              />
            </div>
          </LogoGrid>
        </Content>

        <CallToAction
          title={
            "Associez-vous à nous dès aujourd’hui et optez pour un avenir de l’hôtellerie tourné vers l’excellence."
          }
          subtitle={"Contactez-nous pour en savoir plus."}
        >
          <Button
            destination={"/fr/contact/demandez-une-demonstration/"}
            label={"Demander une démonstration"}
            icon={pageQuery["rightArrowIcon"]}
            white
            blueText
            pill
          />
        </CallToAction>
      </Content>
    </Layout>
  );
};

export default HospitalityIntegrationsPage;

const IconCon = styled.div`
  ${font.imports.sherika}
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and ${mq.maxMd} {
    flex-basis: 95%;
  }

  .icon-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline-con {
    @media only screen and ${mq.maxMd} {
      text-align: center;
      margin: auto;
    }
  }

  .headline {
    font-family: ${font.family.sherika};
    font-weight: 600 !important;
    color: ${brand.colors.pg_orange} !important;
    font-size: 48px !important;
    margin-bottom: 1px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p {
    margin-top: 1px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  ${props =>
    props.whiteOrange &&
    css`
      flex-basis: 30%;
      align-items: flex-start;
      flex-wrap: wrap;

      .headline-con {
        text-align: left;
        h4 {
          color: ${brand.colors.pg_primary_dark} !important;
          font-family: ${font.family.sherika};
          font-size: 22px !important;
          font-weight: 700;
          white-space: pre-line;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
        p {
          color: ${brand.colors.pg_primary_dark};
          font-family: ${font.family.sherika};
          font-weight: 400;
          margin: 20px 0;
          min-height: 96px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        .headline {
          min-height: 60px;
          display: flex;
          align-items: center;
          @media only screen and ${mq.maxMd} {
            text-align: center;
            justify-content: center;
          }
        }
      }
    `}

  ${props =>
    props.whiteOnBlue &&
    css`
      flex-basis: 45%;
      align-items: flex-start;
      justify-content: center;
      text-align: left;

      .icon-con {
        text-align: left;
      }

      .headline-con {
        .headline {
          color: #fff !important;
          font-size: 26px !important;
        }
      }

      p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
      }
    `}
`;

const IconContainer = ({ icon, highlight, headline, copy, ...props }) => {
  const ic = icon ? <GatsbyImage fixed={icon.childImageSharp.fixed} /> : <></>;
  return (
    <IconCon {...props}>
      <div className="icon-con">{ic}</div>
      <div className="headline-con">
        <h4 className="headline-highlight">{highlight}</h4>
        <h4 className="headline">{headline}</h4>
        <p dangerouslySetInnerHTML={{ __html: copy }}></p>
      </div>
    </IconCon>
  );
};

export const query = graphql`
  fragment CruiseLogo on File {
    childImageSharp {
      fluid(maxWidth: 125, webpQuality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
